import React from "react";
import Skeleton from '@mui/material/Skeleton';

import { useAuth0 } from '@auth0/auth0-react';

import UnauthHome from './unauth';
import AvailableClinics from './availableClinics';

const Home = () => {
  const { isAuthenticated } = useAuth0();
  const { isLoading } = useAuth0();

  const getAuthHome = () => {
    return (
      <AvailableClinics />
    )
  }

  const getUnauthHome = () => {
    return (
      <UnauthHome />
    )
  }

  const getLoadingHome = () => {
    return (
      <Skeleton />
    )
  }

  return isLoading ? getLoadingHome() : isAuthenticated ? getAuthHome() : getUnauthHome();
}

export default Home;