import React from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';

import api from '../api'

const CancelClinicDialog = ({clinicId, date, hasRegisteredAttendee, totalInterested, takenByStudentId}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleClickOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleConfirm = () => {
    api.deleteClinic(clinicId).then((data) => {
      if (data.status == 200) {
        if(hasRegisteredAttendee && takenByStudentId) {
          api.removeClinicWeekDate(takenByStudentId, date.toString()).then((data) => {
            if (data.status === 200) {
              window.location.reload(false);
            }
          })
        } else {
          window.location.reload(false);
        }
      }
    })
  }
  
  return (
    <React.Fragment>
      <Button variant="contained" color="error" onClick={handleClickOpen}>
        Cancel Clinic
      </Button>
      <Dialog open={isOpen}>
        <DialogTitle sx={{display: 'flex', alignItems: 'center'}}>Cancel clinic?</DialogTitle>
          <DialogContent>
              { 
                hasRegisteredAttendee && (
                  <Alert sx={{alignItems: 'center', marginBottom: '8px'}} severity="warning">This clinic already has a registered attendee. Cancelling this clinic will notify them via email that they should no longer attend.</Alert>
                )
              }
              { 
                totalInterested >= 1 && (
                  <Alert sx={{alignItems: 'center', marginBottom: '8px'}} severity="info">This clinic has interested doctors. Since they have not been confirmed to attend, they will <b>not</b> be notified by email that the clinic was cancelled, please contact them directly if needed.</Alert>
                )
              }
            <DialogContentText>
              <Typography variant="subtitle2">
                Do you want to cancel the LP clinic on {date.format('dddd')} {date.format('Do')} {date.format('MMMM')} {date.format('YYYY')} at {date.format("HH:mm")}?
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{padding: '20px'}}>
            <Button onClick={handleClose}>No</Button>
            <Button variant="contained" onClick={handleConfirm}>Yes</Button>
          </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default CancelClinicDialog;