import React from "react";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import LinearProgress from '@mui/material/LinearProgress';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid'; 

import calendarImage from '../images/calendar.jpg';

const Loading = () => {
  return (
    <Grid item xs={4} sm ={7} md={8} style={{textAlign: 'center'}}>
      <Card style={{marginTop: '20px', marginBottom: '20px'}}>
        <CardMedia sx={{height: { xs: '30vh', sm: '30vh', md: '50vh'}}} image={calendarImage} />
        <Skeleton animation="wave" variant="text" width={400} sx={{ fontSize: '1.5rem', margin: 'auto' }} />
        <Skeleton variant="rounded" width={'80%'} height={86}  sx={{ margin: 'auto', marginBottom: '32px' }} />
        <Skeleton variant="rounded" width={'80%'} height={144}  sx={{ margin: 'auto', marginBottom: '32px' }} />
        <LinearProgress/>
      </Card>
    </Grid>
  )
}

export default Loading;