import React from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import api from '../api'

const ConfirmAttendeeDialog = ({clinicId, studentId, firstName, lastName}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleClickOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleConfirm = () => {
    api.addTakenByStudent(clinicId, studentId).then((data) => {
      if (data.status == 200) {
        window.location.reload(false);
      }
    })
  }
  
  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
        <CheckCircleOutlineIcon sx={{fontSize: '16px', marginRight: '4px'}} />
        Confirm
      </Button>
      <Dialog open={isOpen}>
        <DialogTitle sx={{display: 'flex', alignItems: 'center'}}>Choose {firstName} {lastName}?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography variant="subtitle2">
                Do you want to accept {firstName} {lastName} onto this clinic?
              </Typography>
              <Typography variant="subtitle2">
                They will automatically be informed by email.
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{padding: '20px'}}>
            <Button onClick={handleClose}>No</Button>
            <Button variant="contained" onClick={handleConfirm}>Yes</Button>
          </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default ConfirmAttendeeDialog;