import React from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import api from '../api'

const RegisterInterestDialog = ({clinicId, date, initialStudentValues}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleClickOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleCancel = () => {
    api.removeTakenByStudent(clinicId, initialStudentValues._id).then((data) => {
      if (data.status === 200) {
        api.removeClinicWeekDate(initialStudentValues._id, date.toString()).then((data) => {
          if (data.status === 200) {
            window.location.reload(false);
          }
        })
      }
    })
  }
  
  return (
    <React.Fragment>
      <Tooltip title="Can't make it anymore? Please cancel here to free up the space for someone else." placement="top" arrow>
        <Button variant="outlined" sx={{width: {xs: '100%', sm:'45%', md: '45%'}, float: 'right', display: 'flex', alignItems: 'center'}} color="error" onClick={handleClickOpen}>
          I can't make it
        </Button>
      </Tooltip>
      <Dialog open={isOpen}>
        <DialogTitle sx={{display: 'flex', alignItems: 'center'}}>Cancel your attendance?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography variant="paragraph">
                Do you want to cancel your attendance from the LP clinic on {date.format('dddd')} {date.format('Do')} {date.format('MMMM')} {date.format('YYYY')} at {date.format("HH:mm")}?
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{padding: '20px'}}>
            <Button onClick={handleClose}>No</Button>
            <Button variant="contained" onClick={handleCancel}>Yes</Button>
          </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default RegisterInterestDialog;