import React from 'react';
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';
import api from '../api'

const AdminProtectedRoute = ({ component, ...args }) => {
  const { user } = useAuth0();
  const {  email } = user;
  const [isUserAdmin, setIsAdmin] = React.useState(null);

  React.useEffect(() => {
    api.getAdminUserByEmail(email)
      .then(data => {
        const isAdmin = data.data.data;

        setIsAdmin(isAdmin);
      });
  }, []);

  const Component = withAuthenticationRequired(component, args);
  return isUserAdmin ? <Component /> : 'Not authorised'; 
};

export default AdminProtectedRoute;