import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Dialog from '@mui/material/Dialog';
import Alert from '@mui/material/Alert';
import Checkbox from '@mui/material/Checkbox';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/InfoOutlined';

import api from '../api'

const RegisterInterestDialog = ({clinicId, date, initialStudentValues}) => {
  const { user } = useAuth0();
  const { email } = user;
  const [isOpen, setIsOpen] = React.useState(false);
  const [isPrivacyAgreed, setIsPrivacyAgreed] = React.useState(initialStudentValues?.isPrivacyAgreed);
  const [currentLevelValue, setCurrentLevelValue] = React.useState(initialStudentValues?.currentLevel);
  const [currentClinicWeekValue, setCurrentClinicWeekValue] = React.useState('no');
  const [currentProficiencyValue, setCurrentProficiencyValue] = React.useState(initialStudentValues?.currentProficiency);
  const [firstNameValue, setFirstNameValue] = React.useState(initialStudentValues?.firstName);
  const [lastNameValue, setLastNameValue] = React.useState(initialStudentValues?.lastName);

  const handleClickOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handlePrivacyCheckbox = (event) => {
    setIsPrivacyAgreed((event.target).checked)
  }

  const handleCurrentLevelChange = (event) => {
    setCurrentLevelValue((event.target).value);
  };

  const handlecurrentClinicWeekChange = (event) => {
    setCurrentClinicWeekValue((event.target).value);
  }

  const handleCurrentProficiencyChange = (event) => {
    setCurrentProficiencyValue((event.target).value);
  };

  const handleFirstNameChange = (event) => {
    setFirstNameValue((event.target).value);
  }

  const handleLastNameChange = (event) => {
    setLastNameValue((event.target).value);
  }

  const handleRegister = () => {
    if(!initialStudentValues) {
      api.addStudent(email, currentLevelValue, currentProficiencyValue, firstNameValue, lastNameValue, currentClinicWeekValue === 'yes' ? date.toString() : null, isPrivacyAgreed).then((data) => {
        if (data.data.success) {
          api.addAvailableStudent(clinicId, data.data.id).then((data) => {
            if (data.status === 200) {
              handleClose();
              window.location.reload(false);
            }
          })
        }
      })
    } else {
      if (initialStudentValues.currentLevel !== currentLevelValue || initialStudentValues.currentProficiency !== currentProficiencyValue || initialStudentValues.firstName !== firstNameValue || initialStudentValues.lastName !== lastNameValue || currentClinicWeekValue === 'yes') {
        api.updateStudent(initialStudentValues._id, currentLevelValue, currentProficiencyValue, firstNameValue, lastNameValue, currentClinicWeekValue === 'yes' ? date.toString() : null, isPrivacyAgreed);
      }

      api.addAvailableStudent(clinicId, initialStudentValues._id).then((data) => {
        if (data.status === 200) {
          handleClose();
          window.location.reload(false);
        }
      })
    }
  }
  
  return (
    <React.Fragment>
      <Button variant="outlined" sx={{width: {xs: '100%', sm:'45%', md: '45%'}, float: 'right', fontSize: {xs: '9pt', sm: '10pt'}}}  onClick={handleClickOpen}>Register interest</Button>
      <Dialog open={isOpen}>
        <DialogTitle>{date.format('dddd')} {date.format('Do')} {date.format('MMMM')} {date.format('YYYY')} at {date.format("HH:mm")}</DialogTitle>
          <DialogContent sx={{padding: '40px'}}>
            <DialogContentText>
              <Typography variant="subtitle2">
                You are registering your interest in this LP clinic. This will inform James that you are available. Please do not register interest on dates which you are unsure of your availability.
                Please await confirmation that you have been accepted onto the clinic before attending.
              </Typography>
            </DialogContentText>
            <TextField
              disabled
              margin="dense"
              id="email"
              label="Email Address"
              type="email"
              fullWidth
              value={email}
              variant="outlined"
              sx={{marginTop: '32px'}}
              helperText={
                <Tooltip arrow title='Please log out, and sign up or login with the correct email address'>
                  <Typography variant="subtitle2" sx={{fontSize: '10px', float: 'right'}}>Not you?</Typography>
                </Tooltip>
              }
            />
            <Box sx={{marginTop: '16px'}}>
              <TextField
                autoFocus
                required
                margin="dense"
                id="firstName"
                label="First Name"
                value={firstNameValue}
                variant="outlined"
                onChange={handleFirstNameChange}
              />
              <TextField
                required
                margin="dense"
                id="lastName"
                label="Last Name"
                value={lastNameValue}
                variant="outlined"
                sx={{marginLeft: {xs: '0', sm: '16px'}}}
                onChange={handleLastNameChange}
              />
            </Box>
            <FormControl required sx={{marginTop: '28px'}}>
              <FormLabel id="clinic-week">Is this clinic date due to occur during a pre-arranged clinic week?</FormLabel>
              <RadioGroup
                aria-labelledby="clinic-week"
                name="clinic-week-group"
                value={currentClinicWeekValue}
                onChange={handlecurrentClinicWeekChange}
              >
              <FormControlLabel value='yes' control={<Radio size="small" />} label={
                <Typography sx={{display: 'flex', alignItems:'center'}}>
                  Yes
                </Typography>
              } />
              <FormControlLabel value='no' control={<Radio size="small" />} label={
                <Typography sx={{display: 'flex', alignItems:'center'}}>
                  No
                </Typography>
              } />
              </RadioGroup>
              {currentClinicWeekValue === 'yes' && (
                <Alert sx={{alignItems: 'center', marginTop: '16px'}} severity="info">
                  By selecting yes, you will be prioritised for this clinic on <b>{date.format('dddd')} {date.format('Do')} {date.format('MMMM')} {date.format('YYYY')} at {date.format("HH:mm")}</b>. Please double-check this date falls during your assigned clinic week.
                </Alert>
              )}
            </FormControl>
            <FormControl required sx={{marginTop: '28px'}}>
              <FormLabel id="current-level">What is your current training grade?</FormLabel>
              <RadioGroup
                aria-labelledby="current-level"
                name="current-level-group"
                value={currentLevelValue}
                onChange={handleCurrentLevelChange}
              >
              <FormControlLabel value="registrar" control={<Radio size="small" />} label={
                <Typography sx={{display: 'flex', alignItems:'center'}}>
                  Registrar grade ST4+
                  <Tooltip title='Or equivalent, eg.: trust grade, clinical fellow, SAS' placement="right" arrow>
                    <InfoIcon sx={{height: '16px'}} />
                  </Tooltip>
                </Typography>
              } />
              <FormControlLabel value="imt3" control={<Radio size="small" />} label={
                <Typography sx={{display: 'flex', alignItems:'center'}}>
                  IMT3
                  <Tooltip title='Or equivalent, eg.: ACCS, trust grade, clinical fellow, SAS' placement="right" arrow>
                    <InfoIcon sx={{height: '16px'}} />
                  </Tooltip>
                </Typography>
              } />
              <FormControlLabel value="imt2" control={<Radio size="small" />} label={
                <Typography sx={{display: 'flex', alignItems:'center'}}>
                  IMT2
                  <Tooltip title='Or equivalent, eg.: ACCS, trust grade, clinical fellow, SAS' placement="right" arrow>
                    <InfoIcon sx={{height: '16px'}} />
                  </Tooltip>
                </Typography>
              } />
              <FormControlLabel value="imt1" control={<Radio size="small" />} label={
                <Typography sx={{display: 'flex', alignItems:'center'}}>
                  IMT1
                  <Tooltip title='Or equivalent, eg.: ACCS, trust grade, clinical fellow, SAS' placement="right" arrow>
                    <InfoIcon sx={{height: '16px'}} />
                  </Tooltip>
                </Typography>
              } />
                <FormControlLabel value="f2" control={<Radio size="small" />} label="F2" />
              </RadioGroup>
            </FormControl>
            <FormControl required sx={{marginTop: '28px'}}>
              <FormLabel id="current-level">What is your current proficiency with LPs?</FormLabel>
              <RadioGroup
                aria-labelledby="current-level"
                name="current-level-group"
                value={currentProficiencyValue}
                onChange={handleCurrentProficiencyChange}
              >
                <FormControlLabel value="independent" control={<Radio size="small" />} label={
                  <Typography>
                    Independent
                    <Typography component="div" variant="paragraph" sx={{fontSize: '12px'}}>Looking for more practice</Typography>
                  </Typography>
                } />
                <FormControlLabel sx={{marginTop: '8px'}} value="limitedSupervision" control={<Radio size="small" />} label={
                  <Typography>
                    Under limited supervision
                    <Typography component="div" variant="paragraph" sx={{fontSize: '12px'}}>Looking to become independent</Typography>
                  </Typography>
                } />
                <FormControlLabel sx={{marginTop: '8px'}} value="directSupervision" control={<Radio size="small" />} label={
                  <Typography>
                    Under direct supervision
                    <Typography component="div" variant="paragraph" sx={{fontSize: '12px'}}>Looking to reduce to limited supervision</Typography>
                  </Typography>
                } />
                <FormControlLabel sx={{marginTop: '8px'}} value="knowledgeable" control={<Radio size="small" />} label={
                  <Typography>
                    Knowledgeable about LPs/received skills-lab training
                    <Typography component="div" variant="paragraph" sx={{fontSize: '12px'}}>Looking for real-life practical experience</Typography>
                  </Typography>
                } />
                <FormControlLabel sx={{marginTop: '8px'}} value="limitedKnowledge" control={<Radio size="small" />} label={
                  <Typography>
                    Limited knowledge of lumbar puncture
                    <Typography component="div" variant="paragraph" sx={{fontSize: '12px'}}>Looking for observational experience</Typography>
                  </Typography>
                } />
              </RadioGroup>
            </FormControl>
            <Alert sx={{alignItems: 'center', marginTop: '16px'}} severity="info">Please only register interest if you are available on <b>{date.format('dddd')} {date.format('Do')} {date.format('MMMM')} {date.format('YYYY')} at {date.format("HH:mm")}</b>. If you find you can no longer make this slot after registering, please unregister using this website to free up the space for another person.</Alert>
            {!initialStudentValues && (
              <FormControl required sx={{marginTop: '28px'}}>
                <FormControlLabel control={<Checkbox onChange={handlePrivacyCheckbox} />} label={<Typography sx={{fontSize: '10pt'}}>I  agree to the processing of my data according to the <a href='/privacy-policy' target='_blank'>privacy policy</a></Typography>} />
              </FormControl>
            )}
          </DialogContent>
          <DialogActions sx={{padding: '20px'}}>
            <Button onClick={handleClose}>Cancel</Button>
            <Button disabled={currentLevelValue === null || currentProficiencyValue === null || !isPrivacyAgreed} variant="contained" onClick={handleRegister}>Register Interest</Button>
          </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default RegisterInterestDialog;