import React from "react";
import moment from "moment";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link'; 
import ProfileIcon from '@mui/icons-material/Person2';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import ConfirmAttendeeDialog from "./confirm-attendee-dialog";

const LevelMap = {
  "registrar": "Registrar grade ST4+",
  "imt3": "IMT 3",
  "imt2": "IMT 2",
  "imt1": "IMT 1",
  "f2": "F2",
}

const ProficiencyMap = {
  "independent": "Independent",
  "limitedSupervision": "Limited supervision",
  "directSupervision": "Direct supervision",
  "knowledgeable": "Knowledgeable",
  "limitedKnowledge": "Limited knowledge",
}

const ProficiencyColourMap = {
  "independent": "error",
  "limitedSupervision": "success",
  "directSupervision": "warning",
  "knowledgeable": "error",
  "limitedKnowledge": "error",
}

const ChooseAttendeeDialog = ({clinicId, date, totalInterested, availableStudents}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleClickOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  
  return (
    <React.Fragment>
      <Button disabled={totalInterested === 0} variant="contained" sx={{marginRight: '16px'}} onClick={handleClickOpen}>
        Choose Attendee
      </Button>
      <Dialog open={isOpen}> 
        <DialogTitle sx={{display: 'flex', alignItems: 'center'}}>Choose attendee</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography component="div" sx={{marginBottom: '32px'}} variant="subtitle2">
                {`Click 'confirm' to select one of the following doctors for this clinic on ${date.format('dddd')} ${date.format('Do')} ${date.format('MMMM')} ${date.format('YYYY')} at ${date.format("HH:mm")}`}
              </Typography>
              {availableStudents?.map((student) => {
                const isClinicWeek = student.clinicWeekDates.some((clinicDate) => clinicDate === date.toString());
                return (
                <Card variant="outlined" sx={{display: 'flex', width: '100%', alignItems: 'center', marginBottom: '16px', padding: '8px', borderColor: isClinicWeek ? '#81b378' : undefined, borderWidth: isClinicWeek ? '2px' : undefined}}>
                  <Box sx={{width: '10%', height: '100px', textAlign: 'center'}}>
                    <ProfileIcon sx={{transform: 'scale(3)', height: '100%' }} color='secondary' />
                  </Box>
                  <CardContent sx={{ flex: '1 0 auto', width: '50%', textAlign: 'left', paddingLeft: '16px' }}> 
                    <Typography variant="h6">
                      {`${student.firstName} ${student.lastName}`}
                      {isClinicWeek && (
                          <Chip color='success' sx={{marginLeft: '8px'}} label={(
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                              Clinic week
                            </Box>
                          )} />
                      )}
                    </Typography>
                    <Box sx={{marginBottom: '8px'}}>
                      <Link sx={{display: 'flex', alignItems: 'center'}} underline="hover" href={`mailto:${student.email}`} variant="subtitle2">
                        <MailOutlineIcon sx={{fontSize: '16px', marginRight: '2px'}} />{student.email}
                      </Link>
                    </Box>
                    <Chip sx={{marginRight: '8px'}} label={(
                      <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <WorkHistoryIcon sx={{height: '16px'}} />
                        {LevelMap[student.currentLevel]}
                      </Box>
                    )} />
                    <Chip color={ProficiencyColourMap[student.currentProficiency]} sx={{marginRight: '8px'}} label={(
                      <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <SupervisedUserCircleIcon sx={{height: '16px'}} />
                        {ProficiencyMap[student.currentProficiency]}
                      </Box>
                    )} />
                  </CardContent>
                  <Box>
                    <ConfirmAttendeeDialog clinicId={clinicId} studentId={student._id} firstName={student.firstName} lastName={student.lastName} />
                  </Box>
                </Card>
              )})}
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{padding: '20px'}}>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default ChooseAttendeeDialog;