import React from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import api from '../api'

const RemoveAttendeeDialog = ({clinicId, date, takenByStudentId, firstName, lastName}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleClickOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleCancel = () => {
    api.removeTakenByStudent(clinicId, takenByStudentId).then((data) => {
      if (data.status == 200) {
        api.removeClinicWeekDate(takenByStudentId, date.toString()).then((data) => {
          if (data.status === 200) {
            window.location.reload(false);
          }
        })
      }
    })
  }
  
  return (
    <React.Fragment>
      <Button variant="outlined" color="error" sx={{marginRight: '16px'}} onClick={handleClickOpen}>
        Remove Attendee
      </Button>
      <Dialog open={isOpen}>
        <DialogTitle sx={{display: 'flex', alignItems: 'center'}}>Remove this attendee?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography variant="subtitle2">
                Do you want to remove {firstName} {lastName} from the LP clinic on {date.format('dddd')} {date.format('Do')} {date.format('MMMM')} {date.format('YYYY')} at {date.format("HH:mm")}?
              </Typography>
              <Typography variant="subtitle2">
                They will automatically be informed, that they were removed from this clinic, by email.
              </Typography>
              <Typography variant="subtitle2">
                The clinic will become available again, and any previous doctors who marked themselves as interested can be accepted onto the clinic.
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{padding: '20px'}}>
            <Button onClick={handleClose}>No</Button>
            <Button variant="contained" onClick={handleCancel}>Yes</Button>
          </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default RemoveAttendeeDialog;