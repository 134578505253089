import React from "react";
import moment from "moment";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import CardContent from '@mui/material/CardContent';
import Tooltip from '@mui/material/Tooltip';

import api from '../api'
import RegisterInterestDialog from "./register-interest-dialog";
import CancelAttendanceDialog from "./cancel-attendance-dialog";

const ClinicDateCard = ({clinicId, isInterested, date, totalInterested, isStudentLoading, initialStudentValues, isAccepted}) => {
  const momentDate = moment(date);

  const handleDeleteInterest = () => {
    api.removeAvailableStudent(clinicId, initialStudentValues._id).then((data) => {
      if (data.status === 200) {
        api.removeClinicWeekDate(initialStudentValues._id, momentDate.toString()).then((data) => {
          if (data.status === 200) {
            window.location.reload(false);
          }
        })
      }
    })
  }
  
  return (
    <Card style={{backgroundColor: '#ebf3fac2', width: '80%', margin: 'auto', display: 'flex', marginBottom: '20px'}}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: {xs: '30%', sm: '20%'} }}>
        <CardContent sx={{maxWidth: {xs: '60px', md: '75px'}, padding: '16px 0', width: '100%'}}>
          <Typography component="div" variant="h2" sx={{fontSize: '2.5rem', fontWeight: '400', padding: '8px', textAlign: 'center', background: '#ffffff', borderRadius: '8px 8px 0 0'}}>
            {momentDate.format('DD')}
          </Typography>
          <Typography component="div" variant="h5" sx={{fontSize: {xs: '1rem', sm: '1.5rem'}, textAlign: 'center', background: '#c55447', borderRadius: '0 0 8px 8px', color: '#ffffff'}}>
            {momentDate.format('MMM')}
          </Typography>
        </CardContent>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left', width: {xs: '75%', sm: '80%', md: '80%'} }}>
        <CardContent>
          <Typography component="div" variant="h5">
            LP Clinic
          </Typography>
          <Typography component="div" variant="subtitle1">
            {momentDate.format('dddd')} {momentDate.format('Do')} {momentDate.format('MMMM')} {momentDate.format('YYYY')} at {momentDate.format("HH:mm")}
          </Typography>
          { isAccepted ? (
            <Box sx={{width: {xs: '100%'}, marginTop: {xs: '8px'}}}>
              <CancelAttendanceDialog initialStudentValues={initialStudentValues} date={momentDate} clinicId={clinicId} />
            </Box>
          ) : 
            isInterested ? (
              <Tooltip arrow placement='right' title='Click the X button to unregister'>
                <Chip color="secondary" label="You're interested" onDelete={handleDeleteInterest}/>
              </Tooltip>
            ) : (
              <React.Fragment>
                {totalInterested > 0 && (
                  <Box>
                    <Chip label={`${totalInterested} ${totalInterested > 1 ? 'people' : 'person'} interested`} />
                  </Box>
                )}
                <Box sx={{width: {xs: '100%'}, marginTop: {xs: '8px'}}}>
                  {!isStudentLoading && (
                    <RegisterInterestDialog initialStudentValues={initialStudentValues} date={momentDate} clinicId={clinicId} />
                  )}
                </Box>
              </React.Fragment>
            )
          }
        </CardContent>
      </Box>
      </Card>
  )
}

export default ClinicDateCard;