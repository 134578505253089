import React from "react";
import { Route, Routes } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { Loading } from "./components";
import { Home, AdminPanel, Privacy } from "./views";
import AdminProtectedRoute from "./auth/protected-route";

const theme = createTheme({
  palette: {
    primary: {
      light: '#5f71bd',
      main: '#2b468c',
      dark: '#00205e',
      contrastText: '#ffffff'
    },
    secondary: {
      light: '#ffc776',
      main: '#fa9647',
      dark: '#c26717',
    },
    error: {
      light: '#fc8473',
      main: '#c55447',
      dark: '#8f241f',
    },
    warning: {
      light: '#ffad5a',
      main: '#ed7d2b',
      dark: '#b54f00',
    },
    info: {
      light: '#addeff',
      main: '#79aceb',
      dark: '#457db8',
    },
    success: {
      light: '#b2e5a7',
      main: '#81b378',
      dark: '#53834c',
      contrastText: '#ffffff'
    }
  },
  typography: {
    fontFamily: [
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

const App = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline>
          <Grid container spacing={2} justifyContent='center' alignItems='center' columns={{ xs: 4, sm: 8, md: 12 }} style={{ minHeight: '100vh', background: '#cfd8dc' }}>
            <Loading />
          </Grid>
        </CssBaseline>
      </ThemeProvider>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <Grid container spacing={2} justifyContent='center' alignItems='center' columns={{ xs: 4, sm: 8, md: 12 }} style={{ minHeight: '100vh', background: '#cfd8dc' }}>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route
              path="/admin"
              element={<AdminProtectedRoute component={AdminPanel} />}
            />
            <Route
              path="/privacy-policy"
              element={<Privacy />}
            />
          </Routes>
        </Grid>
      </CssBaseline>
    </ThemeProvider>
    
  );
};

export default App;