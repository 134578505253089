import React from "react";
import moment from "moment";
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid'; 
import Link from '@mui/material/Link'; 
import TextField from '@mui/material/TextField';
import ProfileIcon from '@mui/icons-material/Person2';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';

import api from '../api'
import calendarImage from '../images/calendar.jpg';

import ClinicDateDialog from '../components/clinic-date-dialog';
import LogoutButton from '../components/logout-button';
import CancelClinicDialog from "../components/cancel-clinic-dialog";
import CreateClinicDialog from '../components/create-clinic-dialog';
import RemoveAttendeeDialog from "../components/remove-attendee-dialog";
import ChooseAttendeeDialog from "../components/choose-attendee-dialog";

const LevelMap = {
  "registrar": "Registrar grade ST4+",
  "imt3": "IMT 3",
  "imt2": "IMT 2",
  "imt1": "IMT 1",
  "f2": "F2",
}

const ProficiencyMap = {
  "independent": "Independent",
  "limitedSupervision": "Limited supervision",
  "directSupervision": "Direct supervision",
  "knowledgeable": "Knowledgeable",
  "limitedKnowledge": "Limited knowledge",
}

const ProficiencyColourMap = {
  "independent": "error",
  "limitedSupervision": "success",
  "directSupervision": "warning",
  "knowledgeable": "error",
  "limitedKnowledge": "error",
}


const AdminPanel = () => {
  const [clinics, setClinics] = React.useState(null);
  const [students, setStudents] = React.useState(null);
  const [completedClinics, setCompletedClinics] = React.useState(null);
  const [clinicNotes, setClinicNotes] = React.useState({});
  const [clinicsWithChangedNotes, setClinicsWithChangedNotes] = React.useState([]);
  
  React.useEffect(() => {
    api.getAllClinics()
      .then(data => {
        const clinicData = data.data.data;

        setClinics(clinicData);
      });
  }, []);

  React.useEffect(() => {
    api.getAllStudents()
      .then(data => {
        const studentData = data.data.data;

        setStudents(studentData);
      });
  }, []);

  React.useEffect(() => {
    api.getCompletedClinics()
      .then(data => {
        const completedClinicsData = data.data.data;

        setCompletedClinics(completedClinicsData);
      });
  }, []);

  const getAvailableClinicsSubheader = () => {
    return (
      <div style={{margin: 'auto', marginTop: '16px', width: '80%'}}>
        <Typography component="div" variant="subtitle2">
          These clinics are awaiting you to confirm which doctor should attend. You can also edit clinic dates here, or remove upcoming clinics with no attendee.
        </Typography>
      </div>
    )
  }

  const getConfirmedClinicsSubheader = () => {
    return (
      <div style={{margin: 'auto', marginTop: '16px', width: '80%'}}>
        <Typography component="div" variant="subtitle2">
          These clinics have a confirmed attendee, use this to change details, remove the attendee or cancel the clinic.
        </Typography>
      </div>
    )
  }

  const getCompletedClinicsSubheader = () => {
    return (
      <div style={{margin: 'auto', marginTop: '16px', width: '80%'}}>
        <Typography component="div" variant="subtitle2">
          These clinics are set in the past. Use this to update doctor details after the clinic, or mark it as cancelled retrospectively.
        </Typography>
      </div>
    )
  }

  const handleConfirmAttendance = (clinicId, wasAttended) => {
    api.confirmSlotAttendance(clinicId, wasAttended).then((data) => {
      if (data.status == 200) {
        window.location.reload(true);
      }
    })
  }

  const handleSaveNotes = () => {
    clinicsWithChangedNotes.forEach((clinicId) => {
      api.updateNotes(clinicId, clinicNotes[clinicId]).then((data) => {
        if (data.status == 200) {
          window.location.reload(true);
        }
      })
    })
  }

  const handleChangeClinicNotes = (notes, clinicId) => {
    const note = {
      [clinicId]: notes
    };

    setClinicNotes(Object.assign(clinicNotes, note));
    setClinicsWithChangedNotes([...clinicsWithChangedNotes, clinicId]);
  }

  const availableClinics = clinics?.filter((clinic) => !clinic.takenByStudentId);
  const confirmedClinics = clinics?.filter((clinic) => clinic.takenByStudentId);

  return (
    <Grid item xs={4} sm ={7} md={10} style={{textAlign: 'center'}}>
      <Card sx={{height: {xs: '100vh', sm: '60vh', md: '95vh'}, overflow: 'scroll'}}>
        <CardMedia sx={{height: { xs: '60vh', sm: '40vh', md: '40vh'}, width: '50%', margin: 'auto'}} image={calendarImage} />
        <CardHeader 
          title="Available Clinics" 
          subheader={getAvailableClinicsSubheader()} 
        />
        <CreateClinicDialog />
        <CardContent sx={{display: 'flex', flexWrap: 'wrap'}}>
          {availableClinics?.map((clinic) => {
            const momentDate = moment(clinic.date);
            const availableStudents = students?.filter((student) => clinic.availableStudentIds.includes(student._id));
            const totalInterested = clinic.availableStudentIds.length;
            return (
              <Card style={{backgroundColor: '#ebf3fac2', width: '46%', margin: '0 2% 20px 2%', minHeight: '100px'}}>
                <CardHeader title={(
                    <span style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                      {`${momentDate.format('ddd')} ${momentDate.format('Do')} ${momentDate.format('MMMM')} ${momentDate.format('YYYY')} at ${momentDate.format("HH:mm")}`}
                      <ClinicDateDialog clinicId={clinic._id} date={momentDate} totalInterested={totalInterested} />
                    </span>
                )} />
                <CardContent>
                  <Chip label={`${totalInterested} ${totalInterested !== 1 ? 'people' : 'person'} available`} />
                  <Box sx={{marginTop: '16px'}}>
                    <ChooseAttendeeDialog clinicId={clinic._id} date={momentDate} totalInterested={totalInterested} availableStudents={availableStudents} />
                    <CancelClinicDialog clinicId={clinic._id} date={momentDate} totalInterested={totalInterested} />
                  </Box>
                </CardContent>
              </Card>
            );
            })}
        </CardContent>
        <CardHeader 
          title="Confirmed Clinics" 
          subheader={getConfirmedClinicsSubheader()} 
        />
        <CardContent sx={{display: 'flex', flexWrap: 'wrap'}}>
          {confirmedClinics?.map((clinic) => {
            const momentDate = moment(clinic.date);
            const totalInterested = clinic.availableStudentIds.length;
            const student = students?.filter((student) => student._id === clinic.takenByStudentId)[0];
            const isClinicWeek = student?.clinicWeekDates?.some((clinicDate) => clinicDate === momentDate.toString());
            return (
              <Card style={{backgroundColor: '#ebf3fac2', width: '46%', margin: '0 2% 20px 2%', minHeight: '100px'}}>
                <CardHeader title={(
                    <span style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                      {`${momentDate.format('ddd')} ${momentDate.format('Do')} ${momentDate.format('MMMM')} ${momentDate.format('YYYY')} at ${momentDate.format("HH:mm")}`}
                      <ClinicDateDialog clinicId={clinic._id} date={momentDate} totalInterested={totalInterested} hasRegisteredAttendee />
                    </span>
                )} />
                <CardContent>
                  <Box sx={{display: 'flex'}}>
                    <Box sx={{width: '30%', height: '100px'}}>
                      <ProfileIcon sx={{transform: 'scale(5)', height: '120%' }} color='secondary' />
                    </Box>
                    <CardContent sx={{ flex: '1 0 auto', width: '70%', textAlign: 'left', paddingLeft: '0px' }}> 
                      <Typography variant="h6">
                        {`${student?.firstName} ${student?.lastName}`}
                        {isClinicWeek && (
                            <Chip color='success' sx={{marginLeft: '8px'}} label={(
                              <Box sx={{display: 'flex', alignItems: 'center'}}>
                                Clinic week
                              </Box>
                            )} />
                        )}
                      </Typography>
                      <Box sx={{marginBottom: '8px'}}>
                        <Link sx={{display: 'flex', alignItems: 'center'}} underline="hover" href={`mailto:${student?.email}`} variant="subtitle2">
                          <MailOutlineIcon sx={{fontSize: '16px', marginRight: '2px'}} />{student?.email}
                        </Link>
                      </Box>
                      <Chip sx={{marginRight: '8px'}} label={(
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                          <WorkHistoryIcon sx={{height: '16px'}} />
                          {LevelMap[student?.currentLevel]}
                        </Box>
                      )} />
                      <Chip color={ProficiencyColourMap[student?.currentProficiency]} sx={{marginRight: '8px'}} label={(
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                          <SupervisedUserCircleIcon sx={{height: '16px'}} />
                          {ProficiencyMap[student?.currentProficiency]}
                        </Box>
                      )} />
                    </CardContent>
                  </Box>
                  <Box sx={{marginTop: '16px'}}>
                    <RemoveAttendeeDialog clinicId={clinic._id} date={momentDate} takenByStudentId={clinic?.takenByStudentId} firstName={student?.firstName} lastName={student?.lastName} />
                    <CancelClinicDialog clinicId={clinic._id} date={momentDate} totalInterested={totalInterested} hasRegisteredAttendee takenByStudentId={clinic?.takenByStudentId} />
                  </Box>
                </CardContent>
              </Card>
            );
            })}
        </CardContent>
        <CardHeader 
          title="Completed Clinics" 
          subheader={getCompletedClinicsSubheader()} 
        />
        <CardContent sx={{display: 'flex', flexWrap: 'wrap'}}>
          {completedClinics?.map((clinic) => {
            const momentDate = moment(clinic.date);
            const attendedBy = students.find((student) => student._id === clinic.takenByStudentId);
            const isConfirmed = clinic.confirmedSlot;

            return (
              <Card style={{backgroundColor: '#ebf3fac2', width: '46%', margin: '0 2% 20px 2%', minHeight: '100px'}}>
                <CardHeader title={(
                    <span style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                      {`${momentDate.format('ddd')} ${momentDate.format('Do')} ${momentDate.format('MMMM')} ${momentDate.format('YYYY')} at ${momentDate.format("HH:mm")}`}
                    </span>
                )} />
                <CardContent>
                  <Chip color={isConfirmed ? 'success' : isConfirmed === false ? 'error' : 'secondary'} label={isConfirmed ? `Confirmed: Attended by ${attendedBy?.email}` : isConfirmed === false ? `Confirmed: Slot was not attended by ${attendedBy?.email}` : `Unconfirmed: Given to ${attendedBy?.email || 'nobody'}`} />
                  <Box sx={{marginTop: '16px'}}>
                    Did this training go ahead?
                    <Box>
                      <Button color={isConfirmed === false ? 'grey' : 'success'} variant="contained" sx={{marginRight: '8px'}} onClick={() => handleConfirmAttendance(clinic._id, true)}>Yes</Button>
                      <Button color={!isConfirmed ? 'error' : 'grey'} variant="contained" onClick={() => handleConfirmAttendance(clinic._id, false)}>No</Button>
                    </Box>
                    <Box>
                        <TextField
                          placeholder="Add notes"
                          multiline
                          rows={3}
                          value={typeof clinicNotes[clinic._id] === 'undefined' ? clinic.notes : clinicNotes[clinic._id]}
                          onChange={(e) => handleChangeClinicNotes(e.target.value, clinic._id)}
                          sx={{width: '100%', marginTop: '8px'}}
                        />
                        {clinicsWithChangedNotes.includes(clinic._id) && (
                          <Button color='success' onClick={() => handleSaveNotes()} variant="contained" sx={{marginTop: '8px'}}>Save notes</Button>
                        )}
                      </Box>
                  </Box>
                </CardContent>
              </Card>
            );
            })}
        </CardContent>
        <ButtonGroup orientation="vertical" style={{width: '50%'}}> 
          <LogoutButton /> 
        </ButtonGroup>
      </Card>
    </Grid>
  )
}

export default AdminPanel;