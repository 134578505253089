import React from "react";
import dayjs from 'dayjs';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import TextField from '@mui/material/TextField';

import api from '../api'

var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const CreateClinicDialog = ({clinicId, date, hasRegisteredAttendee, totalInterested}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [dateValue, setDate] = React.useState(dayjs().day(4 + 7).hour(13).minute(30));
  const [timeValue, setTime] = React.useState(dayjs().day(4 + 7).hour(13).minute(30))
  const [value, setValue] = React.useState(dayjs().day(4 + 7).hour(13).minute(30));

  const handleDateChange = (newValue) => {
    setDate(newValue);
    setValue(dayjs().date(newValue.$D).month(newValue.$M).year(newValue.$y).hour(timeValue.$H).minute(timeValue.$m));
  };

  const handleTimeChange = (newValue) => {
    setTime(newValue);
    setValue(dayjs().date(dateValue.$D).month(dateValue.$M).year(dateValue.$y).hour(newValue.$H).minute(newValue.$m));
  };

  const handleClickOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSave = () => {
    if (value !== dayjs(date)) {
      api.createClinic(dayjs(value).format()).then((data) => {
        if (data.status === 201) {
          handleClose();
          window.location.reload(false);
        }
      });
    }
  }
  
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <React.Fragment>
        <Button variant="contained" color='secondary' sx={{width: '40%', marginBottom: '32px'}} onClick={handleClickOpen}>Add New Clinic</Button>
        <Dialog open={isOpen}>
          <DialogTitle>Create Clinic</DialogTitle>
            <DialogContent>
              <Box sx={{marginTop: '16px'}}>
                <DesktopDatePicker
                  label="Date"
                  inputFormat="DD/MM/YYYY"
                  value={value}
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
              <Box sx={{marginTop: '32px'}}>
                <TimePicker
                  label="Time"
                  value={value}
                  onChange={handleTimeChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </DialogContent>
            <DialogActions sx={{padding: '20px'}}>
              <Button onClick={handleClose}>Cancel</Button>
              <Button variant="contained" onClick={handleSave}>Create Clinic</Button>
            </DialogActions>
        </Dialog>
      </React.Fragment>
    </LocalizationProvider>
  )
}

export default CreateClinicDialog;