import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ButtonGroup from '@mui/material/ButtonGroup';
import Card from '@mui/material/Card';
import Alert from '@mui/material/Alert';
import CardMedia from '@mui/material/CardMedia';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid'; 

import api from '../api'
import calendarImage from '../images/calendar.jpg';

import LogoutButton from '../components/logout-button';
import ClinicDateCard from '../components/clinic-date-card';

const AvailableClinics = () => {
  const { user } = useAuth0();
  const { email } = user;
  const [clinics, setClinics] = React.useState(null);
  const [initialStudentValues, setInitialStudentValues] = React.useState(null);
  const [isStudentLoading, setStudentLoading] = React.useState(false);

  React.useEffect(() => {
    api.getAllClinics()
      .then(data => {
        const clinicData = data.data.data;

        setClinics(clinicData);
      });
  }, []);

  React.useEffect(() => {
    setStudentLoading(true);
    api.getStudentByEmail(email)
      .then(data => {
        const studentValues = data.data.data;

        setInitialStudentValues(studentValues);
        setStudentLoading(false);
      });
  }, []);

  const getConfirmedClinicsSubheader = () => {
    return (
      <div style={{margin: 'auto', marginTop: '16px', width: '80%'}}>
        <Typography component="div" variant="subtitle2">
          James has confirmed your attendance onto the following LP clinic(s). You should attend this clinic at the date and time stated. <br/> Please make sure you have familiarised yourself with lumbar punctures (safety checking, consenting, technical aspects, and post-procedure care) before attending.
        </Typography>
        <Typography component="div" variant="subtitle2">
          If you can no longer attend, please cancel using this website or reach out to James directly via text or email.
        </Typography>
      </div>
    )
  }
  
  const getAvailableClinicsSubheader = () => {
    return (
      <div style={{margin: 'auto', marginTop: '16px', width: '80%'}}>
        <Typography component="div" variant="subtitle2">
          All available LP clinics are listed below. <b>You can register interest for as many clinics as you are capable of attending. Please only register your interest on days which you are available.</b> James will take a look through and accept one person per clinic.
        </Typography>
        <Typography component="div" variant="subtitle2">
          If accepted, you will recieve a confirmation email. Please only attend clinics you have been accepted onto.
        </Typography>
      </div>
    )
  }

  const availableClinics = clinics?.filter((clinic) => !clinic.takenByStudentId);
  const acceptedClinics = clinics?.filter((clinic) => initialStudentValues && clinic.takenByStudentId === initialStudentValues?._id);

  return (
    <Grid item xs={4} sm ={7} md={8} style={{textAlign: 'center'}}>
      {user.email_verified ? (
        <Card style={{marginTop: '20px', marginBottom: '20px'}}>
          <CardMedia sx={{height: { xs: '25vh', sm: '25vh', md: '50vh'}}} image={calendarImage} />
          {acceptedClinics?.length ? (
            <React.Fragment>
              <CardHeader 
                title="Confirmed LP Clinics" 
                subheader={getConfirmedClinicsSubheader()} 
              />
              <CardContent style={{textAlign: 'center'}}>
                {
                  acceptedClinics?.map((clinic) => (
                    <ClinicDateCard key={clinic._id} initialStudentValues={initialStudentValues} clinicId={clinic._id} date={clinic.date} isAccepted />
                  ))
                }
              </CardContent>
            </React.Fragment>
          ) : null}
          <CardHeader 
            title="Available LP Clinics" 
            subheader={getAvailableClinicsSubheader()} 
          />
          <CardContent style={{textAlign: 'center'}}>
            {
              availableClinics?.map((clinic) => (
                <ClinicDateCard key={clinic._id} initialStudentValues={initialStudentValues} isStudentLoading={isStudentLoading} clinicId={clinic._id} date={clinic.date} totalInterested={clinic.availableStudentIds.length} isInterested={clinic.availableStudentIds.some((availableId) => availableId === initialStudentValues?._id)} />
              ))
            }
            <ButtonGroup orientation="vertical" style={{width: '50%'}}> 
              <LogoutButton /> 
            </ButtonGroup>
          </CardContent>
        </Card>
      ) : (
        <Card style={{marginTop: '20px', marginBottom: '20px'}}>
          <CardMedia sx={{height: { xs: '30vh', sm: '30vh', md: '50vh'}}} image={calendarImage} />
          <CardHeader 
            title="Available LP Clinics" 
          />
          <CardContent style={{textAlign: 'center'}}>
          <Alert sx={{alignItems: 'center', width: '60%', margin: 'auto'}} severity="warning">Please check your email and verify your account to access the available LP clinics.</Alert>
            <ButtonGroup orientation="vertical" style={{width: '50%'}}> 
              <LogoutButton /> 
            </ButtonGroup>
          </CardContent>
        </Card>
      )}
      
      <Typography sx={{marginBottom: '36px'}}>
      <Link sx={{textDecoration: 'none'}} href='/privacy-policy' target='_blank'>privacy policy</Link>
      </Typography>
    </Grid>
  )
}

export default AvailableClinics;