import React from "react";
import ButtonGroup from '@mui/material/ButtonGroup';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';  
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import calendarImage from '../images/calendar.jpg';

import LoginButton from '../components/login-button';
import SignupButton from "../components/signup-button";

const UnauthHome = () => {
  const getUnauthHomeSubheader = () => {
    return (
      <div style={{marginTop: '16px'}}>
        Please login or sign-up to access the clinic training booking system.
      </div>
    )
  }

  return (
    <Grid item xs={4} sm ={7} md={8} style={{textAlign: 'center'}}>
      <Card sx={{height: {xs: '100vh', sm: '60vh', md: '95vh'}}}>
        <CardMedia sx={{height: { xs: '25vh', sm: '25vh', md: '60vh'}}} image={calendarImage} />
        <CardHeader 
          title="LP Clinic Training Booking System" 
          subheader={getUnauthHomeSubheader()} 
        />
        <CardContent>
          <ButtonGroup orientation="vertical" style={{width: '50%'}}> 
            <LoginButton /> 
            <SignupButton /> 
            <div style={{"font-size": "8pt"}}>
              Protected by <Link underline="hover" style={{color: '#000000'}} href="https://auth0.com/" target="_blank">Auth0</Link>
            </div>
          </ButtonGroup>
        </CardContent>
      </Card>

      <Typography sx={{margin: '18px 0 36px 0'}}>
      <Link sx={{textDecoration: 'none'}} href='/privacy-policy' target='_blank'>privacy policy</Link>
      </Typography>
    </Grid>
  )
}

export default UnauthHome;