import axios from 'axios'

const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL
})

export const getAllClinics = () => api.get(`/clinics`)
export const getAllStudents = () => api.get(`/students`)
export const getCompletedClinics = () => api.get(`/completedClinics`)

export const deleteClinic = (clinicId) => api.delete(`/clinic/${clinicId}`)
export const createClinic = (date) => api.post('/clinic', { date: date });

export const removeAvailableStudent = (clincId, email) => api.put(`/clinic/removeAvailableStudent/${clincId}`, { idToRemove: email })
export const removeTakenByStudent = (clincId, studentId) => api.put(`/clinic/removeTakenByStudent/${clincId}`, { idToRemove: studentId })
export const addTakenByStudent = (clincId, studentId) => api.put(`/clinic/addTakenByStudent/${clincId}`, { idToAdd: studentId })
export const updateClinicDate = (clincId, date) => api.put(`/clinic/updateDate/${clincId}`, { date: date })
export const addAvailableStudent = (clincId, studentId) => api.put(`/clinic/addAvailableStudent/${clincId}`, { studentId: studentId })
export const confirmSlotAttendance = (clincId, wasAttended) => api.put(`/clinic/confirmSlotAttendance/${clincId}`, { confirmedSlot: wasAttended })
export const updateNotes = (clincId, notes) => api.put(`/clinic/updateNotes/${clincId}`, { notes: notes })

export const getStudentByEmail = (email) => api.get(`/student/${email}`)
export const updateStudent = (studentId, currentLevel, currentProficiency, firstName, lastName, clinicWeekDate, isPrivacyAgreed) => api.put(`/student/${studentId}`, { currentLevel: currentLevel, currentProficiency: currentProficiency, firstName: firstName, lastName: lastName, clinicWeekDates: [clinicWeekDate], isPrivacyAgreed });
export const addStudent =(email, currentLevel, currentProficiency, firstName, lastName, clinicWeekDate, isPrivacyAgreed) => api.post('/student', { email: email, currentLevel: currentLevel, currentProficiency: currentProficiency, firstName: firstName, lastName: lastName, clinicWeekDates: [clinicWeekDate], isPrivacyAgreed })
export const removeClinicWeekDate = (studentId, dateToRemove) => api.put(`/student/removeClinicWeekDate/${studentId}`, { dateToRemove: dateToRemove })

export const getAdminUserByEmail = (email) => api.get(`/adminUser/${email}`)

const apis = {
  getAllClinics,
  getAllStudents,
  getCompletedClinics,
  deleteClinic,
  createClinic,
  addTakenByStudent,
  removeAvailableStudent,
  removeTakenByStudent,
  removeClinicWeekDate,
  getStudentByEmail,
  addAvailableStudent,
  confirmSlotAttendance,
  updateNotes,
  getAdminUserByEmail,
  updateClinicDate,
  addStudent,
  updateStudent
}

export default apis