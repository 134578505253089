import React from "react";
import dayjs from 'dayjs';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';

import api from '../api'

const ClinicDateDialog = ({clinicId, date, hasRegisteredAttendee, totalInterested}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [dateValue, setDate] = React.useState(dayjs(date));
  const [timeValue, setTime] = React.useState(dayjs(date))
  const [value, setValue] = React.useState(dayjs(date));

  const handleDateChange = (newValue) => {
    setDate(newValue);
    setValue(dayjs().date(newValue.$D).month(newValue.$M).year(newValue.$y).hour(timeValue.$H).minute(timeValue.$m));
  };

  const handleTimeChange = (newValue) => {
    setTime(newValue);
    setValue(dayjs().date(dateValue.$D).month(dateValue.$M).year(dateValue.$y).hour(newValue.$H).minute(newValue.$m));
  };

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSave = () => {
    if (value !== dayjs(date)) {
      api.updateClinicDate(clinicId, dayjs(value).format()).then((data) => {
        if (data.status === 200) {
          handleClose();
          window.location.reload(false);
        }
      });
    }
  }
  
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <React.Fragment>
        <Tooltip title="Edit clinic date or time" placement="top" arrow>
          <EditIcon color='primary' sx={{marginLeft: '8px', fontSize: '14px'}} onClick={handleClickOpen} />
        </Tooltip>
        <Dialog open={isOpen}>
          <DialogTitle>Edit Clinic Date</DialogTitle>
            <DialogContent>
              { 
                hasRegisteredAttendee && (
                  <Alert sx={{alignItems: 'center', marginBottom: '8px'}} severity="warning">This clinic already has a registered attendee. They will be notified of the change via email, but will not be removed from the clinic. You should confirm that they can still attend the new date and time before making this change.</Alert>
                )
              }
              { 
                (totalInterested >= 1 || (hasRegisteredAttendee && totalInterested >= 2)) && (
                  <Alert sx={{alignItems: 'center', marginBottom: '8px'}} severity="info">This clinic has interested doctors. Changing the date or time will remove their availibility from this clinic. Since they are not confirmed, they will not be notified by email.</Alert>
                )
              }
              <Box sx={{marginTop: '16px'}}>
                <DesktopDatePicker
                  label="Date"
                  inputFormat="DD/MM/YYYY"
                  value={value}
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
              <Box sx={{marginTop: '32px'}}>
                <TimePicker
                  label="Time"
                  value={value}
                  onChange={handleTimeChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </DialogContent>
            <DialogActions sx={{padding: '20px'}}>
              <Button onClick={handleClose}>Cancel</Button>
              <Button variant="contained" onClick={handleSave}>Save Changes</Button>
            </DialogActions>
        </Dialog>
      </React.Fragment>
    </LocalizationProvider>
  )
}

export default ClinicDateDialog;